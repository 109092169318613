import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export default function ProjectCard(props) {
  return (
    // Make absolute postioned DIV respect the padding of the parent element - overflow: hidden to parent, position: relative to child
    <Box
      as={motion.div}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      w={{ base: "100%", md: "50%", lg: "33%" }}
      p="4"
      overflow="hidden"
    >
      <LinkBox h="100%">
        <LinkOverlay as={GatsbyLink} to={props.linkTo}>
          <Flex flexDirection="column" h="100%">
              <Box position="relative" w="100%">
                <GatsbyImage
                  image={getImage(props.listingImage)}
                  alt={props?.listingImageAlt}
                  imgStyle={{ width: "100%", height: "auto" }}
                  objectFit="contain"
                />
                <Box
                  width="100%"
                  height="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  display={{ base: "none", md: "block" }}
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  whileHover={{ opacity: 1 }}
                  bg="rgba(0, 0, 0, 0.5)"
                  zIndex="1"
                >
                  <Flex
                    flexDirection="column"
                    w="100%"
                    h="100%"
                    justifyContent="flex-end"
                    alignItems="center"
                    p="4"
                  >
                    <Box>
                      <Heading as="h4" variant="project-card-title">
                        {props.listingTitle}
                      </Heading>
                      {props.projectSubtitle && (
                        <Text
                          color="#B4B665"
                          textAlign="center"
                          fontWeight="700"
                          textTransform="uppercase"
                          fontSize="0.75rem"
                        >
                          {props.projectSubtitle}
                        </Text>
                      )}
                      <Text color="#e4bd0a" textAlign="center" fontWeight="700">
                        See More <ArrowForwardIcon />
                      </Text>
                    </Box>
                  </Flex>
                </Box>
    
                {/* Mobile cards */}
                <Box
                  width="100%"
                  height="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  display={{ base: "block", lg: "none" }}
                  as={motion.div}
                  bg="rgba(0, 0, 0, 0.5)"
                  zIndex="1"
                >
                  <Flex
                    flexDirection="column"
                    w="100%"
                    h="100%"
                    justifyContent="flex-end"
                    alignItems="center"
                    p="4"
                  >
                    <Box>
                      <Heading as="h4" variant="project-card-title">
                        {props.listingTitle}
                      </Heading>
                      {props.projectSubtitle && (
                        <Text
                          color="#B4B665"
                          textAlign="center"
                          fontWeight="700"
                          textTransform="uppercase"
                          fontSize="0.75rem"
                        >
                          {props.projectSubtitle}
                        </Text>
                      )}
                      <Text color="#e4bd0a" textAlign="center" fontWeight="700">
                        See More <ArrowForwardIcon />
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Flex bg="#000" py="2" h="100%" justifyContent="center" alignItems="center" display={{base: 'none', lg: 'flex'}}>
                <Text
                  color="white"
                  textAlign="center"
                  mb="0"
                  textTransform="uppercase"
                  fontWeight="700"
                  fontSize="0.8rem"
                >
                  {props.listingTitle}
                </Text>
              </Flex>
          </Flex>
        </LinkOverlay>
      </LinkBox>
    </Box>
  );
}
