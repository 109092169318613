import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { 
  Box,
  Heading,
} from '@chakra-ui/react'
import { SliceZone } from '@prismicio/react';
import { components } from '../components/Slices';
import Seo from '../components/Seo'
import ProjectCardFilter from '../components/ProjectCardFilter/ProjectCardFilter'


const ProjectsPage = ({ data, location }) => {
  
  const pageContent = data.prismicProjectsPage.data;
  
  return (
    <Layout
        pageLocation={location}
        headerImage={pageContent.header_image?.localFile}
        headerImageAlt={pageContent.header_image?.alt}
    >
      <Seo title={pageContent.seo_title.text} description={pageContent.meta_description.text} />
      <Box my="16">
          <Heading as="h2" variant="h2" textAlign="center">
            {pageContent.page_title}
          </Heading>
      </Box>
        
       <Box>
           <SliceZone
            slices={pageContent.body}
            components={components}
            />
       </Box>

       <ProjectCardFilter 
        location={location}
       />

    </Layout>
  )
}

export const query = graphql`
{
    prismicProjectsPage {
      uid
      data {
        page_title
        seo_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`

export default ProjectsPage;