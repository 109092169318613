import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
    Box,
    Flex,
    Heading,
    Container
} from '@chakra-ui/react';

import ProjectCard from './ProjectCard';

const listings = [
    { cat: 'Residential' },
    { cat: 'Workplace' },
    { cat: 'Education' },
]

const ProjectCardFilter = ({ location }) => {
    const [listing, setListing] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const data = useStaticQuery(graphql`
    {
        allPrismicProjectPage(sort: {fields: data___page_title, order: ASC}) {
            edges {
              node {
                uid
                data {
                  project_type
                  page_title
                  project_subtitle
                  project_images {
                    image {
                      alt
                      localFile {
                        childImageSharp {
                          gatsbyImageData(aspectRatio: 1.5, layout: FULL_WIDTH, placeholder: BLURRED, transformOptions: {cropFocus: CENTER})
                        }
                      }
                    }
                  }
                }
                url
              }
            }
          }
    }
  `)

  const prismicData = data.allPrismicProjectPage.edges;
  
  const projectListing = location.state?.project_type;

  const handleChange = e => {
      if (e.target.checked) {
          setListing([...listing, e.target.value]);
          console.log([...listing]);
      } else {
          setListing(listing.filter(id => id !== e.target.value));
      }
  };

  // Get state from link
  useEffect(() => {
    if (projectListing === 'Workplace') {
      setListing(['Workplace']);
    } else if (projectListing === 'Residential') {
      setListing(['Residential']);
    }
    else if (projectListing === 'Education') {
      setListing(['Education']);
    }
     else {
      setListing([]);
    }
  }, [projectListing]);
 
  // Filter listings
  useEffect(() => { 
    if (listing.length === 0) {
        setFiltered(prismicData);
    } 
    else {
        setFiltered(
            prismicData.filter((item) =>
                listing.some((category) => item.node.data.project_type.includes(category))
            )
        );
    }
    }, [listing, prismicData]);

    return (
        <Container maxW="container.xl">
          <Box my="8" px="4">
            {/* <Heading as="h3" fontSize="1rem" mb="2">Show: </Heading> */}
                {listings.map((listings, index) => (
                        <Box key={index} p="1" display="inline-block" textAlign="center" mr="4">
                              <label class="check" htmlFor={listings.cat}>
                                <input
                                    id={listings.cat}
                                    type="checkbox"
                                    onChange={handleChange}
                                    value={listings.cat}
                                    checked={listing.includes(listings.cat)}
                                />
                                <span>{listings.cat}</span>
                              </label>
                        </Box>
                  )
                )}
          </Box>
        
          {filtered.length > 0 ? (
             <Flex w="100%" flexWrap="wrap" mb="24"> 
             {filtered.map((item, idx) => {
             return (
                   <ProjectCard
                       key={idx}
                       listingImage={item.node.data.project_images[0].image.localFile}
                       listingImageAlt={item.node.data.project_images[0].image.alt}
                       listingTitle={item.node.data.page_title}
                       projectType={item.node.data.project_type}
                       linkTo={item.node.url}
                       projectSubtitle={item.node.data.project_subtitle}
                   />
             )}
             )}
         </Flex>
          ) : (
            <Box>
              <Heading as="h3" fontSize="1.5rem" mb="12" textAlign="center">No projects found for this category.</Heading>
            </Box>
            )}
        </Container>
    )
}

export default ProjectCardFilter;